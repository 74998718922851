import React from "react"
import { graphql } from "gatsby"
import { Container, Col, Row } from "styled-bootstrap-grid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ContentWrap, YellowBorderWrap } from "./Services/styles"
import parse from 'html-react-parser';
import Contact from "../components/Contact"

const BlogPostTemplate = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={data?.wpPost?.title}
        description={data?.wpPost?.excerpt}
        url={data?.wpPost?.slug}
      />
      <Container>
        <Row>
          <Col col={12}>
            <YellowBorderWrap style={{ margin: `64px 0` }}>
              <h1>{data?.wpPost?.title}</h1>
              <h2>{parse(data?.wpPost?.excerpt)}</h2>
            </YellowBorderWrap>
          </Col>
          <Col col={12} style={{ textAlign: "center" }}>
            {data?.wpPost?.featuredImage?.node?.localFile?.publicURL && (
              <img
                alt={data?.wpPost?.title}
                src={data?.wpPost?.featuredImage?.node?.localFile?.publicURL}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col col={12}>
            <ContentWrap>
              {parse(data.wpPost?.content ?? '')}
            </ContentWrap>
          </Col>
        </Row>
      </Container>
      <Contact />
    </Layout>
  )
}
export default BlogPostTemplate;

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      slug
      author {
        node {
            name
        }
      }
      featuredImage {
        node {
          srcSet
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
